import React, { FunctionComponent, ComponentType } from "react";
import { Box, Typography, useTheme, Theme } from "@material-ui/core";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";
import styled from "@emotion/styled";

interface ContentBlockProps {
    logoImage: import("gatsby-source-filesystem").FileSystemNode;
    title: string,
    content: string
    maxWidth?: number
    logoPadding?: number
}

type boxProps = import('@material-ui/core/Box').BoxProps;

const PaddedBox = styled<ComponentType<boxProps>, { theme: Theme }>(Box)(props => `
        padding: 32px;

        ${props.theme.breakpoints.down("sm")} {
            padding: 16px;
        }
    `);

const InnerContent = styled<ComponentType<boxProps>, { theme: Theme }>(Box)(props => `
        padding: 0px 16px;

        ${props.theme.breakpoints.down("sm")} {
            padding: 0px 8px;
        }
    `);

const ContentBlock: FunctionComponent<ContentBlockProps> = (props) => {
    const theme = useTheme<Theme>();

    return (<PaddedBox theme={theme}>
        {props.logoImage &&
            (props.logoImage.childImageSharp as any).fixed &&
            <Box display="flex" justifyContent="center" py={props.logoPadding || 0}>
                <Img fixed={(props.logoImage.childImageSharp as any).fixed} />
            </Box>
        }

        {props.logoImage &&
            (props.logoImage.childImageSharp as any).fluid &&
            <Box maxWidth={props.maxWidth || 175 } py={props.logoPadding || 0} margin="auto">
                <Img fluid={(props.logoImage.childImageSharp as any).fluid} />
            </Box>
        }
        <Typography variant="h3" component="h2" align="center" gutterBottom>{props.title}</Typography>
        <InnerContent theme={theme}>
            <ReactMarkdown source={props.content} />
        </InnerContent>
    </PaddedBox>);
}


export default ContentBlock;