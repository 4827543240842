import React from "react";
import BlogTeaser from "../components/blog-teaser";
import { useStaticQuery, graphql } from "gatsby";
import { useTheme, Theme, Container, Grid, Typography } from "@material-ui/core";
import Hero from "../components/hero";
import Section from "../components/section";
import ReactMarkdown from "react-markdown";
import ContentBlock from "../components/contentBlock";
import styled from "@emotion/styled";
import { ShapeArea, ShapeStack, ShapeDown, ClipPath } from "../components/shapes";
import Img from "gatsby-image";
import SEO from "../components/seo";
import MainLayout from "../layouts/mainLayout";

interface LocationProps {
    location: Location;
}

const ShapeContain = styled(Grid)`
    overflow: hidden;
`;

const ShapedImage = styled(Img)`
    clip-path: url(#what-clip)
`;

const AboutPage: React.FC<LocationProps> = (props) => {
    const theme = useTheme<Theme>();
    const data = useStaticQuery(graphql`
    query {
        seo: aboutJson(block: {eq: "seo" }) {
            title
            description
            image {
                childImageSharp {
                    resize(width: 750, height: 300, cropFocus: ENTROPY) {
                        src
                        width
                        height
                    }
                }
            }
        }
        hero: aboutJson(block: {eq: "hero"}) {
            title
            tagline
            ctaText
            ctaUrl
            cta2Text
            cta2Url
            image {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 4160, maxHeight: 3120) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        about: aboutJson(block: {eq: "about"}) {
            tagline
            content
            quote
        }
        team: aboutJson(block: {eq: "team" }) {
            title
            content
            logoImage {
                childImageSharp {
                    fluid(maxWidth: 400, cropFocus: CENTER) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            image {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
    `);
 
    return (
        <MainLayout transparentNav>
            <SEO {...data.seo} location={props.location} />
            <Hero {...data.hero} overlayColor={theme.palette.primary.dark} heroHeight={85} />
            <Section tightBottom>
                <Container>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h1" align="left">{data.about.tagline}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <ReactMarkdown source={data.about.content} />
                        </Grid>
                    </Grid>
                </Container>
            </Section>

            <Section tightTop noBottom>
                <Typography variant="h2" align="center">"{data.about.quote}"</Typography>
            </Section>

            <Section tightTop noBottom>
                <Container>
                    <Grid alignItems="center" container>
                        <ShapeContain item xs={12} md={6}>
                            <ShapeArea>
                                <ShapeStack>
                                    <ShapeDown color={theme.palette.primary.dark} opacity={30} rotate={75} translateY={60} />
                                    <ShapeDown color={theme.palette.primary.light} opacity={75} scale={0.95} rotate={25} translateX={-15} translateY={60} />
                                </ShapeStack>
                                <ClipPath name="what-clip" />
                                <ShapedImage fluid={data.team.image.childImageSharp.fluid} />
                            </ShapeArea>
                        </ShapeContain>
                        <Grid item xs={12} md={6}>
                            <ContentBlock maxWidth={400} logoPadding={2} {...data.team} />
                        </Grid>
                    </Grid>
                </Container>
            </Section>

            <BlogTeaser />
        </MainLayout>
    )
}

export default AboutPage